
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons-vue'

import AddEditCourse from '@/components/agency/course/AddEditCourse.vue'

export default defineComponent({
  components: {
    AddEditCourse,
    ArrowLeftOutlined,
    HomeOutlined
  },
  setup () {
    const route = useRoute()
    const editCourse = ref<boolean>(true)
    const courseId = ref<string>(route.params.id as string)
    return {
      courseId,
      editCourse
    }
  }
})
